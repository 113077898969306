@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Orbitron", sans-serif;
    background: black;
    @apply tracking-wider text-gray-200;
  }
}
@font-face {
  font-family: "NeueReg";
  src: url("assets/PPNeueMachina-InktrapRegular.otf");
}
@font-face {
  font-family: "NeueIt";
  src: url("assets/PPNeueMachina-InktrapRegularItalic.otf");
}
@font-face {
  font-family: "WorkSans";
  src: url("assets/WorkSans-Regular.ttf");
}
